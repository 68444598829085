import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { useMedia } from 'react-use'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import {
  DrawerCardTypes,
  DrawerPanel,
} from 'components/app/project/drawer-panel'
import HeaderBasic from 'components/app/project/header/header-basic'
import {
  parseLatestCountertopEstimate,
  parseLatestPlan,
  parseRenders,
} from 'context/design/utils'
import useProject from 'context/project/use'
import useProjectDrawerUrls from 'context/project/use-drawer-urls'
import { isImageFile } from 'context/project-file/utils'
import useRoom from 'context/room/use'
import DesignCard from 'design-system/components/cards/design-card'
import PrimaryCard from 'design-system/components/cards/primary-card'
import SecondaryCard from 'design-system/components/cards/secondary-card'
import AppLayout from 'design-system/containers/app-layout'
import TabsContainer from 'design-system/containers/tabs'
import { Breakpoint, Colors } from 'styles/app/system'
import { RoomType } from 'types/room'
import { getRoomImageByType } from 'views/utils'

const RoomHome = (_: RouteComponentProps) => {
  const isSmallDesktop = useMedia(Breakpoint.fromTabletToSmallDesktop)

  const { project } = useProject()
  const getUrlForDrawerType = useProjectDrawerUrls()
  const {
    appliancesIncluded,
    designsPublished,
    floorPlansIncluded,
    inspirationImagesIncluded,
    measurementsIncluded,
    roomPhotosIncluded,
    room,
    typeWithDescription,
  } = useRoom()

  return (
    <AppLayout
      header={
        <HeaderBasic onBack={() => navigate(`/app/projects/${project.id}`)} />
      }
    >
      <Grid stackable>
        <Grid.Row>
          <Grid.Column>
            <h3>{typeWithDescription}</h3>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="row-gap">
          <Grid.Column largeScreen={12} computer={11} tablet={16} mobile={16}>
            {!designsPublished.length ? (
              <GrayContainer>
                <Grid stackable>
                  <Grid.Row stretched className="row-gap">
                    <Grid.Column
                      widescreen={11}
                      largeScreen={11}
                      computer={16}
                      tablet={16}
                      mobile={16}
                    >
                      <DesignCard
                        disabled
                        image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/placeholder-design.svg`}
                        title="Design in progress"
                        kind="status"
                      />
                    </Grid.Column>
                    <Grid.Column
                      widescreen={5}
                      largeScreen={5}
                      computer={16}
                      tablet={16}
                      mobile={16}
                    >
                      {isSmallDesktop ? (
                        <SecondaryCard
                          disabled
                          image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/visual-quote.svg`}
                          subtitle="In progress"
                          title="Visual Quote"
                        />
                      ) : (
                        <PrimaryCard
                          disabled
                          image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/visual-quote.svg`}
                          subtitle="In progress"
                          title="Visual Quote"
                        />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </GrayContainer>
            ) : (
              <TabsContainer
                tabs={designsPublished.map((d) => {
                  const { latestPlan, latestPlanExternalReviewMode } =
                    parseLatestPlan(d)
                  const { latestCountertopEstimate } =
                    parseLatestCountertopEstimate(d)
                  const { rendersReleased } = parseRenders(d)
                  const renderImage = rendersReleased.find((r) =>
                    isImageFile(r.key),
                  )

                  return {
                    key: d.id,
                    label: d.name,
                    content: (
                      <Grid stackable>
                        <Grid.Row stretched className="row-gap">
                          <Grid.Column
                            widescreen={10}
                            largeScreen={10}
                            computer={16}
                            tablet={16}
                            mobile={16}
                          >
                            <DesignCard
                              disabled={!latestPlan}
                              image={
                                renderImage
                                  ? `${process.env.GATSBY_CDN_ROOT}/${renderImage.key}`
                                  : getRoomImageByType(room?.type as RoomType)
                              }
                              status={latestPlanExternalReviewMode}
                              title={d.name}
                              kind="status"
                              url={`/app/projects/${project.id}/rooms/${room?.id}/designs/${d.id}/plan/${latestPlan?.id}`}
                            />
                          </Grid.Column>
                          <Grid.Column
                            widescreen={6}
                            largeScreen={6}
                            computer={16}
                            tablet={16}
                            mobile={16}
                          >
                            {isSmallDesktop || latestCountertopEstimate ? (
                              <CardsContainer>
                                <SecondaryCard
                                  image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/visual-quote-b.svg`}
                                  onClick={() =>
                                    navigate(
                                      `/app/projects/${project.id}/rooms/${room?.id}/designs/${d.id}/quote`,
                                    )
                                  }
                                  title="Visual Quote"
                                  basic
                                />
                                {latestCountertopEstimate && (
                                  <SecondaryCard
                                    image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/countertop-estimate.svg`}
                                    onClick={() =>
                                      window.open(
                                        `${process.env.GATSBY_CDN_ROOT}/${latestCountertopEstimate?.key}`,
                                      )
                                    }
                                    title="Countertop Estimate"
                                    basic
                                  />
                                )}
                              </CardsContainer>
                            ) : (
                              <PrimaryCard
                                image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/visual-quote.svg`}
                                onClick={() =>
                                  navigate(
                                    `/app/projects/${project.id}/rooms/${room?.id}/designs/${d.id}/quote`,
                                  )
                                }
                                title="Visual Quote"
                              />
                            )}
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    ),
                  }
                })}
              >
                {(tab) => tab.content}
              </TabsContainer>
            )}
          </Grid.Column>
          <DrawerColumn largeScreen={4} computer={5} tablet={16} mobile={16}>
            <DrawerPanel
              getUrlForDrawerType={getUrlForDrawerType}
              roomId={room?.id ?? ''}
              sections={[
                {
                  title: 'Room specifications',
                  items: [
                    ...(appliancesIncluded ? [DrawerCardTypes.APPLIANCES] : []),
                    ...(measurementsIncluded
                      ? [DrawerCardTypes.MEASUREMENTS]
                      : []),
                  ],
                  isOpen: true,
                },
                {
                  title: 'Room uploads',
                  items: [
                    ...(floorPlansIncluded ? [DrawerCardTypes.FLOOR_PLAN] : []),
                    ...(roomPhotosIncluded
                      ? [DrawerCardTypes.ROOM_PHOTOS]
                      : []),
                    ...(inspirationImagesIncluded
                      ? [DrawerCardTypes.INSPIRATION]
                      : []),
                  ],
                  isOpen: true,
                },
              ]}
            />
          </DrawerColumn>
        </Grid.Row>
      </Grid>
    </AppLayout>
  )
}

const GrayContainer = styled.div`
  background-color: ${Colors.gray100};
  border-radius: 2px;
  padding: 32px;
  margin-right: 16px;
`

const DrawerColumn = styled(Grid.Column)`
  @media ${Breakpoint.onlyComputer} {
    padding-left: 0 !important;
  }
`
const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 16px;
`

export default RoomHome
