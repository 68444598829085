import React from 'react'

import { navigate } from 'gatsby'
import { Divider, Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import useProject from 'context/project/use'
import SecondaryCard from 'design-system/components/cards/secondary-card'
import { resourcesUrls } from 'views/utils'

const Resources = () => {
  const {
    isTradeProject,
    project,
    paymentProjectDepositPaid,
    orderCabinetryPlaced,
  } = useProject()

  return (
    <>
      <Divider hidden />
      <Grid.Row>
        <Grid.Column>
          <p className="overline">Resources</p>
        </Grid.Column>
      </Grid.Row>
      <GridRow stretched>
        <Grid.Column
          widescreen={4}
          largeScreen={4}
          computer={5}
          tablet={8}
          mobile={16}
        >
          <SecondaryCard
            icon="browser"
            onClick={() => navigate(`/app/projects/${project.id}/call-recaps`)}
            title="Call Recaps"
          />
        </Grid.Column>
        <Grid.Column
          widescreen={4}
          largeScreen={4}
          computer={5}
          tablet={8}
          mobile={16}
        >
          <SecondaryCard
            icon="images"
            onClick={() => navigate(`/app/projects/${project.id}/catalogs`)}
            title="Catalogs"
          />
        </Grid.Column>
        <Grid.Column
          widescreen={4}
          largeScreen={4}
          computer={5}
          tablet={8}
          mobile={16}
        >
          <SecondaryCard
            icon="heart"
            onClick={() => window.open(resourcesUrls.inspirationGuide)}
            title="Inspiration Guide"
          />
        </Grid.Column>
        <Grid.Column
          widescreen={4}
          largeScreen={4}
          computer={5}
          tablet={8}
          mobile={16}
        >
          <SecondaryCard
            icon="ballot-check"
            onClick={() => window.open(resourcesUrls.projectHandbook)}
            title="Project Handbook"
          />
        </Grid.Column>
        <Grid.Column
          widescreen={4}
          largeScreen={4}
          computer={5}
          tablet={8}
          mobile={16}
        >
          <SecondaryCard
            icon="shopping-bag"
            onClick={() =>
              navigate(`/app/projects/${project.id}/sample-orders`)
            }
            title="Samples"
          />
        </Grid.Column>
        {!isTradeProject ? (
          <Grid.Column
            widescreen={4}
            largeScreen={4}
            computer={5}
            tablet={8}
            mobile={16}
          >
            <SecondaryCard
              icon="hammer"
              onClick={() => navigate(`/app/projects/${project.id}/buildzoom`)}
              title="BuildZoom"
            />
          </Grid.Column>
        ) : null}
        {paymentProjectDepositPaid && !orderCabinetryPlaced ? (
          <Grid.Column
            widescreen={4}
            largeScreen={4}
            computer={5}
            tablet={8}
            mobile={16}
          >
            <SecondaryCard
              icon="truck"
              onClick={() => navigate(`/app/projects/${project.id}/timeline`)}
              title="Project Timeline"
            />
          </Grid.Column>
        ) : null}
      </GridRow>
    </>
  )
}

export default Resources

const GridRow = styled(Grid.Row)`
  &&&&&&&& {
    row-gap: 16px;
    div.column {
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }
  }
`
