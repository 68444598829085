import React, { createContext, useMemo } from 'react'

import { parseRoomData } from 'context/room/utils'

import useProject from '../use'

export enum PreProjectDepositCardTypes {
  APPLIANCES = 'appliances',
  APPROVE_DESIGN_BRIEF = 'approveDesignBrief',
  CALL_RECAPS = 'callRecaps',
  CATALOGS = 'catalogs',
  COLLABORATORS = 'collaborators',
  COMPLETE_PROJECT_SETUP = 'completeProjectSetup',
  DESIGN_CALL_RECAP = 'designCallRecap',
  DESIGN_PRESENTATION_RECAP = 'designPresentationRecap',
  FLOOR_PLAN = 'floorPlan',
  INITIAL_COSTING = 'initialCosting',
  INSPIRATION = 'inspiration',
  INSPIRATION_GUIDE = 'inspirationGuide',
  ORDER_SAMPLES = 'orderSamples',
  PAY_DESIGN_DEPOSIT = 'payDesignDeposit',
  PAY_PROJECT_DEPOSIT = 'payProjectDeposit',
  PROJECT_CONSULTATION_RECAP = 'projectConsultationRecap',
  PROJECT_HANDBOOK = 'projectHandbook',
  PROJECT_PROPOSAL = 'projectProposalRecap',
  ROOM_PHOTOS = 'roomPhotos',
  SAMPLES = 'samples',
  SCHEDULE_DESIGN_CALL = 'scheduleDesignCall',
  SCHEDULE_PROJECT_CONSULTATION = 'scheduleProjectConsultation',
}

type PreProjectDepositProviderProps = {
  children: React.ReactNode
}

type IPreProjectDepositContext = {
  keyDocumentsSection: PreProjectDepositCardTypes[]
  resourcesSection: PreProjectDepositCardTypes[]
  toDoSection: PreProjectDepositCardTypes[]
}

export const PreProjectDepositContext =
  createContext<IPreProjectDepositContext>({} as IPreProjectDepositContext)

const PreProjectDepositProvider = ({
  children,
}: PreProjectDepositProviderProps) => {
  const {
    designBriefs,
    initialCostings,
    isTradeProject,
    meetingDesignOccurred,
    meetingDesignScheduled,
    meetingPresentationOccurred,
    meetingProjectConsultationOccurred,
    meetingProjectConsultationScheduled,
    meetingProjectProposalOccurred,
    orderSamplesPlaced,
    paymentDesignDepositPaid,
    paymentProjectDepositPaid,
    paymentsProjectDepositNotSucceeded,
    projectProposals,
    roomPrimary,
    tradeSettings,
  } = useProject()

  const { completeInformationFlowDone } = useMemo(
    () => parseRoomData(roomPrimary, true),
    [roomPrimary],
  )

  const contextValue = useMemo<IPreProjectDepositContext>(() => {
    const toDoSection: PreProjectDepositCardTypes[] = []
    const keyDocumentsSection: PreProjectDepositCardTypes[] = []
    const resourcesSection: PreProjectDepositCardTypes[] = [
      PreProjectDepositCardTypes.CATALOGS,
      PreProjectDepositCardTypes.COLLABORATORS,
      PreProjectDepositCardTypes.INSPIRATION_GUIDE,
      PreProjectDepositCardTypes.PROJECT_HANDBOOK,
    ]

    //* To Do Section: General
    if (!completeInformationFlowDone) {
      toDoSection.push(PreProjectDepositCardTypes.COMPLETE_PROJECT_SETUP)
    }

    //* To Do Section: Trade
    if (isTradeProject) {
      if (
        !meetingProjectConsultationOccurred &&
        !meetingProjectConsultationScheduled &&
        !tradeSettings.skipProjectConsultation
      ) {
        toDoSection.push(
          PreProjectDepositCardTypes.SCHEDULE_PROJECT_CONSULTATION,
        )
      }

      if (
        (meetingProjectConsultationOccurred ||
          tradeSettings.skipProjectConsultation) &&
        !paymentDesignDepositPaid &&
        !tradeSettings?.skipDesignDeposit
      ) {
        toDoSection.push(PreProjectDepositCardTypes.PAY_DESIGN_DEPOSIT)
      }

      if (
        (paymentDesignDepositPaid || tradeSettings?.skipDesignDeposit) &&
        paymentsProjectDepositNotSucceeded.length
      ) {
        toDoSection.push(PreProjectDepositCardTypes.PAY_PROJECT_DEPOSIT)
      }

      if (
        tradeSettings.noBlockOrderSamples ||
        paymentDesignDepositPaid ||
        orderSamplesPlaced
      ) {
        resourcesSection.unshift(PreProjectDepositCardTypes.SAMPLES)
      }
    } else {
      //* To Do Section: Homeowner
      if (
        !meetingDesignScheduled &&
        !meetingDesignOccurred &&
        !paymentDesignDepositPaid
      ) {
        toDoSection.push(PreProjectDepositCardTypes.SCHEDULE_DESIGN_CALL)
      }
      if (meetingDesignOccurred && !paymentDesignDepositPaid) {
        toDoSection.push(PreProjectDepositCardTypes.PAY_DESIGN_DEPOSIT)
      }

      if (paymentDesignDepositPaid && !orderSamplesPlaced) {
        toDoSection.push(PreProjectDepositCardTypes.ORDER_SAMPLES)
      }

      if (
        meetingPresentationOccurred &&
        paymentDesignDepositPaid &&
        !paymentProjectDepositPaid
      ) {
        toDoSection.push(PreProjectDepositCardTypes.PAY_PROJECT_DEPOSIT)
      }

      if (paymentDesignDepositPaid && !roomPrimary.data.designBriefApprovedAt) {
        toDoSection.push(PreProjectDepositCardTypes.APPROVE_DESIGN_BRIEF)
      }

      if (paymentDesignDepositPaid && orderSamplesPlaced) {
        resourcesSection.push(PreProjectDepositCardTypes.SAMPLES)
      }
    }

    //* Key Documents Section: Trade
    if (isTradeProject) {
      if (meetingProjectProposalOccurred && !paymentProjectDepositPaid) {
        keyDocumentsSection.push(PreProjectDepositCardTypes.PROJECT_PROPOSAL)
      }

      if (
        (meetingProjectConsultationOccurred ||
          tradeSettings.skipProjectConsultation) &&
        initialCostings.length
      ) {
        keyDocumentsSection.push(PreProjectDepositCardTypes.INITIAL_COSTING)
      }
    } else {
      //* Important Documents Section: Homeowner
      if (meetingDesignOccurred && !paymentDesignDepositPaid) {
        keyDocumentsSection.push(PreProjectDepositCardTypes.DESIGN_CALL_RECAP)
      }
      if (
        meetingPresentationOccurred &&
        paymentDesignDepositPaid &&
        !paymentProjectDepositPaid
      ) {
        keyDocumentsSection.push(
          PreProjectDepositCardTypes.DESIGN_PRESENTATION_RECAP,
        )
      }
    }

    //* Resources Section
    if (!paymentProjectDepositPaid) {
      if (completeInformationFlowDone) {
        resourcesSection.push(
          PreProjectDepositCardTypes.APPLIANCES,
          PreProjectDepositCardTypes.FLOOR_PLAN,
          PreProjectDepositCardTypes.ROOM_PHOTOS,
          PreProjectDepositCardTypes.INSPIRATION,
        )
      }

      //* Resources Section: Trade
      if (isTradeProject) {
        if (
          meetingProjectConsultationOccurred ||
          meetingProjectProposalOccurred
        ) {
          resourcesSection.push(PreProjectDepositCardTypes.CALL_RECAPS)
        }
      } else {
        //* Resources Section: Homeowner
        if (meetingDesignOccurred || meetingPresentationOccurred) {
          resourcesSection.push(PreProjectDepositCardTypes.CALL_RECAPS)
        }
      }
    }

    return {
      keyDocumentsSection,
      resourcesSection,
      toDoSection,
    }
  }, [
    completeInformationFlowDone,
    designBriefs,
    initialCostings,
    isTradeProject,
    meetingDesignOccurred,
    meetingDesignScheduled,
    meetingPresentationOccurred,
    meetingProjectConsultationOccurred,
    meetingProjectProposalOccurred,
    orderSamplesPlaced,
    paymentDesignDepositPaid,
    paymentProjectDepositPaid,
    projectProposals,
  ])

  return (
    <PreProjectDepositContext.Provider value={contextValue}>
      {children}
    </PreProjectDepositContext.Provider>
  )
}

export const usePreProjectDeposit = () => {
  const context = React.useContext(PreProjectDepositContext)
  if (context === undefined) {
    throw new Error(
      'usePreProjectDeposit must be used within a PreProjectDepositProvider',
    )
  }
  return context
}

export default PreProjectDepositProvider
