import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { Container, Divider, Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import HeaderBasic from 'components/app/project/header/header-basic'
import StepTitleRow from 'components/app/step-title'
import HelpMessage from 'components/shared/help-message'
import useProjectOrderSamplesMany from 'context/project/order-samples/use-many'
import useProject from 'context/project/use'
import useGuideUrls from 'context/project/use-guide-urls'
import useUser from 'context/user/use'
import Button from 'design-system/components/button'
import AppLayout from 'design-system/containers/app-layout'
import { Breakpoint, Colors } from 'styles/app/system'

import OrderSummary from './order-summary'

const Module = (
  props: RouteComponentProps<{
    location: {
      state: {
        fromDesignCall?: boolean
      }
    }
  }>,
) => {
  const { user } = useUser()
  const { fromDesignCall } = props.location?.state || {}
  const {
    project,
    primaryOwner,
    paymentsDesignDepositSucceeded,
    tradeSettings,
  } = useProject()
  const { ordersPending, ordersPlaced } = useProjectOrderSamplesMany()
  const { DesignCall } = useGuideUrls()

  const userOrdering = user || primaryOwner
  const userOrderingHasName = !!userOrdering?.firstName

  const disableCheckout =
    !ordersPending.length ||
    ordersPending.some((o) => !o.shippingAddress) ||
    !userOrderingHasName ||
    (!paymentsDesignDepositSucceeded?.length &&
      !tradeSettings?.noBlockOrderSamples)

  useEffect(() => {
    document.body.scrollTo({ top: 0 })
  }, [])

  return (
    <>
      <AppLayout
        header={
          <HeaderBasic
            onBack={() =>
              fromDesignCall
                ? navigate(DesignCall)
                : navigate(`/app/projects/${project.id}`)
            }
          />
        }
      >
        <Grid stackable>
          <StepTitleRow
            subTitle={
              ordersPlaced.length > 0
                ? 'Click the “Create New Order” button if you’d like to order additional samples.'
                : 'Please verify that these are the samples you’d like to order and confirm your shipping address before proceeding to checkout. If you’d like to order more samples, you can purchase another group of three by clicking the “Add More Samples” button.'
            }
            title="Order Samples"
          />
          <Grid.Row centered style={{ padding: 0 }}>
            <Grid.Column computer={9} tablet={14} mobile={16}>
              <HelpMessage
                closeIcon
                icon="bell-exclamation"
                message="Our samples arrive covered with a protective film. Please remove it once you receive your samples."
                title="Important"
              />
            </Grid.Column>
          </Grid.Row>
          {ordersPending.length ? (
            <>
              <Divider hidden />
              <Grid.Row columns="equal" centered style={{ gap: '20px' }}>
                {ordersPending.map((order, k) => (
                  <OrderSummary
                    key={order.id}
                    onEdit={() =>
                      navigate(
                        `/app/projects/${project.id}/sample-orders/${order.id}`,
                        {
                          state: {
                            ...props.location?.state,
                            fromSamplesView: true,
                          },
                        },
                      )
                    }
                    order={order}
                    title={`Order ${k + 1}`}
                  />
                ))}
              </Grid.Row>
            </>
          ) : null}
          <Divider hidden />
          {!paymentsDesignDepositSucceeded?.length &&
            !tradeSettings?.noBlockOrderSamples && (
              <Grid.Row centered>
                <Grid.Column
                  computer={9}
                  tablet={14}
                  mobile={16}
                  textAlign="center"
                >
                  <HelpMessage
                    icon="triangle-exclamation"
                    message="Design deposit payment is required to order samples."
                  />
                </Grid.Column>
              </Grid.Row>
            )}
          <Grid.Row centered>
            <Grid.Column computer={9} tablet={14} mobile={16}>
              <div style={{ display: 'flex', gap: '16px' }}>
                <Button
                  color="blue"
                  kind="solid"
                  onClick={() =>
                    navigate(`/app/projects/${project.id}/sample-orders/new`, {
                      state: {
                        ...props.location?.state,
                        fromSamplesView: true,
                      },
                    })
                  }
                  text={
                    ordersPlaced.length > 0
                      ? 'Create New Order'
                      : 'Add More Samples'
                  }
                  size="fluid"
                />
                {ordersPending.length > 0 ? (
                  <Button
                    disabled={disableCheckout}
                    kind="solid"
                    onClick={() =>
                      navigate(
                        `/app/projects/${project.id}/sample-orders/checkout`,
                        {
                          state: props.location?.state,
                        },
                      )
                    }
                    text="Checkout"
                    size="fluid"
                  />
                ) : null}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </AppLayout>
      {ordersPlaced.length > 0 ? (
        <PastOrders>
          <Container>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <p className="subtitle">Order history</p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="equal" centered style={{ gap: '20px' }}>
                {ordersPlaced.map((order, k) => (
                  <OrderSummary
                    key={order.id}
                    order={order}
                    title={`Order ${k + 1}`}
                  />
                ))}
              </Grid.Row>
            </Grid>
          </Container>
        </PastOrders>
      ) : null}
    </>
  )
}

export default Module

const PastOrders = styled.section`
  background: ${Colors.gray100};
  flex-grow: 1;
  padding: 30px 0 40px;

  @media ${Breakpoint.onlyMobile} {
    padding-bottom: 100px;
  }
`
