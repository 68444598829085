import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from 'gatsby'
import styled from 'styled-components'

import { useProjectMessageMany } from 'context/project/message/provider-many'
import useProject from 'context/project/use'
import { Breakpoint, Colors } from 'styles/app/system'

const MessagesTrigger = () => {
  const { project } = useProject()
  const { newForMeCount } = useProjectMessageMany()

  return (
    <MessageItem
      className={newForMeCount ? 'active' : ''}
      onClick={() => navigate(`/app/projects/${project.id}/messages`)}
    >
      <div className="icon">
        <FontAwesomeIcon icon={['fal', 'envelope']} />
        {newForMeCount ? (
          <div className="count">
            <p>{newForMeCount}</p>
          </div>
        ) : null}
      </div>
      <p className="up-mobile">Messages</p>
    </MessageItem>
  )
}

export default MessagesTrigger

export const MessageItem = styled.div`
  align-items: center;
  background: ${Colors.gray50};
  cursor: pointer;
  display: flex;
  gap: 12px;
  padding: 10px 20px;
  min-height: 60px;

  p {
    color: ${Colors.gray700};
    font-weight: 300;
    font-size: 12px;
  }

  svg {
    color: ${Colors.gray700};
    font-size: 15px;
  }

  div {
    align-items: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
  }

  div.icon {
    background: ${Colors.gray100};
    width: 38px;
    height: 38px;
    position: relative;
    transition: all 0.2s ease-in-out;
  }

  div.count {
    background: ${Colors.gray700};
    border: 1px solid ${Colors.gray100};
    height: 19px;
    position: absolute;
    right: -5px;
    top: -6px;
    width: 19px;
    z-index: 1;

    p {
      color: ${Colors.white};
      font-size: 10px;
    }
  }

  :hover div.icon {
    background: ${Colors.gray200};
    svg {
      color: ${Colors.gray600};
    }
  }

  &.active {
    p,
    svg {
      color: ${Colors.gray700};
    }

    div.icon {
      background: ${Colors.gray200};
    }

    :hover div.icon {
      background: ${Colors.gray400};
      svg {
        color: ${Colors.white};
      }
    }
  }

  @media ${Breakpoint.onlyMobile} {
    background: ${Colors.white};
    padding: 0;
  }
`
