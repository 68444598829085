import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from 'gatsby'

import { useUserMessageMany } from 'context/project/message/provider-user-many'
import useUser from 'context/user/use'

import { MessageItem } from '../project/message/trigger'

const MessagesTrigger = () => {
  const { company } = useUser()
  const { newForMeCount } = useUserMessageMany()

  return (
    <MessageItem
      className={newForMeCount ? 'active' : ''}
      onClick={() => navigate(`/app/company/${company?.id}/messages`)}
    >
      <div className="icon">
        <FontAwesomeIcon icon={['fal', 'envelope']} />
        {newForMeCount ? (
          <div className="count">
            <p>{newForMeCount}</p>
          </div>
        ) : null}
      </div>
      <p className="up-mobile">Messages</p>
    </MessageItem>
  )
}

export default MessagesTrigger
