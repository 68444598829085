import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'

import HeaderDashboard from 'components/app/project/header/header-dashboard'
import useAuth from 'context/auth/use'
import useProject from 'context/project/use'
import useUser from 'context/user/use'
import AppLayout from 'design-system/containers/app-layout'

import HomeMainDashboard from './main'
import PreProjectDepositIndex from './pre-project-deposit'

const HomeView = (_: RouteComponentProps<{ project_id: string }>) => {
  const { isFORMStaff } = useAuth()
  const { paymentProjectDepositPaid, ordersCabinetryPublished } = useProject()
  const { inTradeProgram, company } = useUser()

  const handleLogoClick = async () => {
    if (isFORMStaff) return
    else if (inTradeProgram && company?.id) {
      navigate(`/app/company/${company.id}`)
    } else {
      navigate(`/app/home`)
    }
  }

  return (
    <AppLayout header={<HeaderDashboard onLogoClick={handleLogoClick} />}>
      {paymentProjectDepositPaid || ordersCabinetryPublished?.length ? (
        <HomeMainDashboard />
      ) : (
        <PreProjectDepositIndex />
      )}
    </AppLayout>
  )
}

export default HomeView
