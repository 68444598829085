import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from 'gatsby'
import { Divider, Dropdown, Image } from 'semantic-ui-react'

import MessagesTrigger from 'components/app/project/message/trigger'
import NotificationBar from 'components/shared/notification-bar'
import useAuth from 'context/auth/use'
import useProject from 'context/project/use'
import { getProjectName } from 'context/project/utils'
import useUser from 'context/user/use'
import HeaderContainer from 'design-system/containers/header'
import { Hamburger } from 'design-system/containers/header/hamburger'
import { FormLogoItem } from 'design-system/containers/header/header'
import HeaderMenu, {
  DropdownMenu,
} from 'design-system/containers/header/header-menu'
import { Project } from 'types/project'
import { User } from 'types/user'

const HeaderDashboard = ({
  onlyMessages,
  onLogoClick,
}: {
  onlyMessages?: boolean
  onLogoClick?: () => void
}) => {
  const { project } = useProject()
  const projectName = getProjectName(project)

  const { user: userCookie } = useAuth()
  const { projects, user: userDb } = useUser()

  const user = userDb || userCookie
  if (!user) return null

  return (
    <HeaderContainer
      leftItems={
        <>
          <FormLogoItem onClick={onLogoClick} />
          {!onlyMessages ? (
            projects.length > 1 ? (
              <HeaderMenu large text={projectName}>
                <ProjectsMenu project={project} projects={projects} />
              </HeaderMenu>
            ) : (
              <p>{projectName}</p>
            )
          ) : null}
        </>
      }
      rightItems={
        <>
          <MessagesTrigger />
          {!onlyMessages ? (
            <>
              <HeaderMenu
                direction="left"
                trigger={<Image className="avatar" src={user?.picture} />}
              >
                <UserMenu user={user} />
              </HeaderMenu>

              {/* Mobile */}
              <Hamburger>
                <Dropdown
                  icon={
                    <FontAwesomeIcon
                      className="dropdown"
                      icon={['fal', 'chevron-down']}
                    />
                  }
                  text="Projects"
                >
                  <DropdownMenu>
                    <ProjectsMenu project={project} projects={projects} />
                  </DropdownMenu>
                </Dropdown>
                <UserMenu user={user} />
              </Hamburger>
            </>
          ) : null}
        </>
      }
    />
  )
}

export default HeaderDashboard

const ProjectsMenu = ({
  project,
  projects,
}: {
  project: Project
  projects: Project[]
}) => {
  return (
    <>
      <Dropdown.Item
        className="primary"
        onClick={() => navigate(`/app`)}
        text={
          <>
            View all
            <FontAwesomeIcon icon={['fal', 'arrow-up-right-from-square']} />
          </>
        }
      />
      {projects.map((p) => (
        <Dropdown.Item
          key={p.id}
          active={project.id === p.id}
          onClick={() => navigate(`/app/projects/${p.id}`)}
          text={
            <>
              {project.id === p.id && (
                <FontAwesomeIcon icon={['fal', 'check']} />
              )}
              {getProjectName(p)}
            </>
          }
        />
      ))}
    </>
  )
}

const UserMenu = (_: { user: User }) => {
  const { logout } = useAuth()
  const {
    paymentsExtraRevisionSucceeded,
    paymentsVisualsUpgradeSucceeded,
    project,
    projectDiscountText,
    projectDiscountHidden,
  } = useProject()
  const { company } = useUser()
  const paymentsSucceeded = !![
    ...paymentsExtraRevisionSucceeded,
    ...paymentsVisualsUpgradeSucceeded,
  ].length

  return (
    <>
      <Dropdown.Item
        onClick={() => navigate(`/app/projects/${project.id}/project-address`)}
        text="Address"
      />
      <Dropdown.Item
        onClick={() => navigate(`/app/projects/${project.id}/collaborators`)}
        text="Collaborators"
      />
      {company?.id && (
        <Dropdown.Item
          onClick={() => navigate(`/app/company/${company.id}/main`)}
          text="Company Information"
        />
      )}
      <Dropdown.Item
        onClick={() => navigate(`/app/projects/${project.id}/profile`)}
        text="Profile"
      />
      <Dropdown.Item
        onClick={() => navigate(`/app/projects/${project.id}/deposits`)}
        text="Deposits"
      />
      {paymentsSucceeded && (
        <Dropdown.Item
          onClick={() => navigate(`/app/projects/${project.id}/payments`)}
          text="Payments"
        />
      )}
      <Divider className="no-margin" />
      <Dropdown.Item onClick={logout} text="Log out" />
      {!!projectDiscountHidden && projectDiscountText && (
        <Dropdown.Item>
          <NotificationBar isLight isDiscount text={projectDiscountText} />
        </Dropdown.Item>
      )}
    </>
  )
}
