import React from 'react'

import { navigate } from 'gatsby'

import AwaitingInvoice from 'components/app/project/payment/awaiting-invoice'
import Checkout from 'components/app/user/checkout'
import PaymentModule from 'components/shared/payment-module'
import PaymentCard from 'components/shared/project/payment/card'
import useAuth from 'context/auth/use'
import PaymentSourceManyProvider from 'context/payment-source/provider-many'
import useProject from 'context/project/use'
import useProjectPaymentMutate from 'context/project/use-payment-mutate'
import ThankYouCard from 'design-system/components/guide/thank-you-card'
import { Section } from 'design-system/components/guide/utils'
import { PaymentMethodType, PaymentType } from 'types/payment'

const ProjectDeposit = () => {
  const { isFORMStaff } = useAuth()
  const {
    paymentsProjectDepositNotSucceeded,
    paymentProjectDepositPaid,
    project,
    refetch,
  } = useProject()
  const { updatePaymentAndRefetch, loadingUpdate } = useProjectPaymentMutate()

  const payment =
    paymentsProjectDepositNotSucceeded?.[0] || paymentProjectDepositPaid

  const pendingInvoice =
    payment?.metadata?.method === PaymentMethodType.INVOICE &&
    payment?.status !== 'succeeded'

  return (
    <Section id="project-deposit">
      <PaymentSourceManyProvider>
        <PaymentModule
          isTradeProject
          hidePaymentRequest={
            (!paymentProjectDepositPaid && isFORMStaff) || !payment
          }
          paymentRequest={
            paymentProjectDepositPaid ? (
              <ThankYouCard
                onClick={() => navigate(`/app/projects/${project.id}`)}
              />
            ) : pendingInvoice ? (
              <AwaitingInvoice />
            ) : (
              <Checkout
                buttonText="Pay Trade Deposit"
                footnote="This is a non-refundable deposit towards your final cabinetry purchase."
                loading={loadingUpdate}
                includeInvoice={false}
                includeTermsAndConditions
                onPay={async (paymentSource, paymentMethod) => {
                  if (!payment?.id) return
                  const isPaymentMethodCard =
                    paymentMethod === PaymentMethodType.CREDIT_CARD
                  const isPaymentMethodInvoice =
                    paymentMethod === PaymentMethodType.INVOICE
                  const stripeSourceId = isPaymentMethodCard
                    ? paymentSource?.id
                    : null

                  await updatePaymentAndRefetch({
                    variables: {
                      data: {
                        stripeSourceId,
                        metadata: {
                          ...payment.metadata,
                          method: paymentMethod,
                          ...(isPaymentMethodInvoice && {
                            generateInvoiceAt: new Date(),
                          }),
                        },
                        ...(isPaymentMethodInvoice && {
                          status: 'pending',
                        }),
                      },
                      where: {
                        id: payment.id,
                      },
                    },
                    onCompleted: async () => {
                      refetch()
                    },
                  })
                }}
                summary={<PaymentCard payment={payment} />}
                summaryTitle="Summary"
              />
            )
          }
          paymentType={PaymentType.PROJECT_DEPOSIT}
          title="Trade deposit: 5% of Initial Estimate"
        />
      </PaymentSourceManyProvider>
    </Section>
  )
}

export default ProjectDeposit
